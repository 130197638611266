import { Switch, Route, Redirect } from 'react-router-dom';
import Landing from 'pages/Landing';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'assets/styles/tailwind.css';
import 'assets/styles/bios.css';

function App() {
    return (
        <Landing />
    );
}

export default App;
