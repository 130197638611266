import Image from '@material-tailwind/react/Image';
import H6 from '@material-tailwind/react/Heading6';
import Paragraph from '@material-tailwind/react/Paragraph';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, {useEffect, useState} from "react";

export default function LandingTeamCard({ img, name, position, linkedIn, gitHub, instagram, bio }) {

    useEffect(() => {
        AOS.init({duration: 1000});
    }, []);


    return (        
        <div data-aos='fade-right' className="flex px-4 lg:mb-0 mb-12">
            <div className="px-6">
                <div id="imageDiv" className='px-24'>
                    <Image src={img} alt={name} raised />
                </div>
                <div className="pt-6 text-center">
                    <H6 color="gray">{name}</H6>
                    <Paragraph color="blueGray">{position}</Paragraph>                    
                    <div className="flex items-center justify-center">
                        <a
                            href={linkedIn}
                            target="_blank"
                        >
                            <Button                               
                                color="lightBlue"
                                buttonType="link"
                                ripple="dark"
                                rounded
                                iconOnly                            
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fab fa-linkedin"
                                    size="lg"
                                />
                            </Button>   
                        </a>                        
                        <a
                            href={gitHub}
                            target="_blank"
                        >
                            <Button                                
                                color="black"
                                buttonType="link"
                                ripple="dark"
                                rounded
                                iconOnly
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fab fa-github"
                                    size="lg"
                                />
                            </Button>
                        </a>
                        <a
                            href={instagram}
                            target="_blank"
                        >
                            <Button                            
                                color="pink"
                                buttonType="link"
                                ripple="dark"
                                rounded
                                iconOnly
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fab fa-instagram"
                                    size="lg"
                                />
                            </Button>
                        </a>                        
                    </div>                                                        
                </div>               
            </div> 
            <div className="flex container items-center top-1/2">
                <div className='biotext pr-29 pb-34'>
                    <Paragraph color="blueGray">{bio}</Paragraph>
                </div>
            </div>                     
        </div>
    );
}
