import React, {useEffect, useState} from "react";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import styled from 'styled-components';
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function ScrollTopButton() {
  
    useEffect(() => {
        AOS.init({duration: 1000});
    }, []);

    
    const scrollToTop = () =>{
        window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
            in place of 'smooth' */
        });
    };
    
    //window.addEventListener('scroll', toggleVisible);

    var TopDiv = styled.div`

        position: fixed; 
        width: 100%;
        left: 94%;
        bottom: 36px;
        height: 20px;
        font-size: 3rem;
        z-index: 100;
        cursor: pointer;
        color: #48a8ee;
        `

    if (window.innerWidth < 768){
        TopDiv = styled.div`

            position: fixed; 
            width: 100%;
            left: 88%;
            bottom: 36px;
            height: 20px;
            font-size: 3rem;
            z-index: 100;
            cursor: pointer;
            color: #48a8ee;
            `
    } 

    return (
        <TopDiv data-aos="fade">
              <Button
                color="lightBlue"
                buttonType="filled"
                size="regular"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={scrollToTop} 
                >
                <Icon name="arrow_upward" size="xlg" />
            </Button>
        </TopDiv>
       
    )
}