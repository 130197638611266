import Title from 'components/landing/Title';
import TeamPicLeft from 'components/landing/TeamPicLeft';
import TeamPicRight from 'components/landing/TeamPicRight';
import TeamCard from 'components/landing/TeamCard';
import Image1 from 'assets/img/Blake800x800.png';
import Image2 from 'assets/img/Darrin800x800.png';
import Image4 from 'assets/img/John800x800.png';
import Image5 from 'assets/img/Trev800x800.png';
import Image6 from 'assets/img/Tre800x800.jpg';
import Image7 from 'assets/img/Bryce800x800.png';
import Image8 from 'assets/img/Calvin800x800.png';
import "assets/styles/teamSection.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, {useEffect, useState} from "react";

export default function TeamSection() {

    useEffect(() => {
        AOS.init({duration: 1000});
    }, []);

    if (window.innerWidth < 768) {
        return (
            <section id="team-section" className="pt-20 pb-48">
                <div className="TeamSection"></div>
                <div data-aos='fade' className="container max-w-7xl mx-auto px-4">
                    <Title heading="Meet the Team">
                    Our team is comprised of several dedicated Computer Science majors from LSU. Be sure to check out our LinkedIn, GitHub, and Instagram profiles to connect with us!
                    </Title>
                    <div className="flex flex-wrap">
                        <TeamCard
                            img={Image1}
                            name="Blake Bollinger"
                            position="CEO, Co-Founder"
                            linkedIn='https://www.linkedin.com/in/blake-bollinger/'
                            gitHub='https://github.com/blakebollinger'
                            instagram='https://www.instagram.com/blakelbollinger/'
                            bio='Blake is a junior at LSU studying Computer Science. He has a passion for mobile app development
                            and creating products people love. He has worked on a wide variety of projects including Apple award-winning machine
                            learning applications, native iOS and Android apps for Fortune 500 companies, and cutting-edge augmented reality
                            research. He brings his passion to Zenith Software to help take the company’s software development to the next level.'
                        />
                        <TeamCard
                            img={Image2}
                            name="Darrin Lea"
                            position="CTO, Co-Founder"                                          
                            linkedIn='https://www.linkedin.com/in/darrin-lea/'
                            gitHub='https://github.com/dlea8'
                            instagram='https://www.instagram.com/darrinlea8/'
                            bio='Darrin is an undergraduate Computer Science and Math major at LSU. He specializes in native Android Development,
                            backend development, and full-stack Web Development with frameworks such as .NET and ReactJS. Along with five years of
                            independent experience, Darrin has industry experience with Android Development through 2021 and 2022 summer internships.
                            He also has one year of industry experience with full-stack Web Development.'
                        />
                        <TeamCard
                            img={Image4}
                            name="John Bajor"
                            position="Head of Design, Co-Founder"
                            linkedIn='https://www.linkedin.com/in/johnbajor/'
                            gitHub='https://github.com/jjbajor'
                            instagram='https://www.instagram.com/jjbajor/'
                            bio='John, a senior studying Computer Science at LSU, has been in love with the creative side
                            of technology for his entire life. From experimenting with modding his old game systems as a
                            kid to building his first PC by hand in high school, he has always been learning new and interesting 
                            ways to personalize his experience with technology. Having a passion for frontend design and 
                            a multiple years of experience as a software developer, he is constantly expanding his knowledge of 
                            all things electronic.'
                        />
                        <TeamCard
                            img={Image5}
                            name="Trevor Baughman"
                            position="Software Engineer"
                            //linkedIn=''
                            gitHub='https://github.com/trevbaugh'
                            instagram='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                            bio="Trevor is a senior studying Computer Science at LSU. He started out developing
                            in Java and JavaScript and has worked on several websites. He has recently started using Python
                            and TypeScript. He enjoys playing video games and is very passionate about desktop computer hardware.
                            He is very excited to work on projects with Zenith and gain experience in the industry.
                            Trevor is very excited to help Zenith grow while working with the company."
                        />
                        <TeamCard
                            img={Image6}
                            name="Tre Landaiche"
                            position="Software Engineer"
                            linkedIn='https://www.linkedin.com/in/tre-landaiche/'
                            gitHub='https://github.com/ThatGuyTre'
                            //instagram=''
                            bio="Tre Landaiche is a Cybersecurity undergraduate with a knack
                            for creating good-looking software. Since joining the crew in
                            March of '22, he has gained experience with Web Development
                            using React frameworks alongside TypeScript. Java, C, and
                            Python are also in his toolbelt and used for smaller side
                            projects. Recently, Tre has gotten into trading card and
                            strategy games, which inspire many ideas for future projects.
                            He hopes to continue with Zenith as long as time allows because
                            of the exposure to working in a professional environment."
                        
                        />
                        <TeamCard
                            img={Image7}
                            name="Bryce Doyle"
                            position="Junior Software Engineer"
                            linkedIn='https://www.linkedin.com/in/bryce-doyle/'
                            gitHub='https://github.com/bdoyle125'
                            instagram='https://www.instagram.com/bdoyle125/'
                            bio="Bryce Doyle is a Software Engineering undergraduate at LSU who
                            has a passion for creating software that solves problems. He has
                            experience using the React framework with TypeScript and JavaScript, as well as
                            coding in Java, C, and C#. Bryce also has experience in automated testing,
                            mocking tests using the Playwright framework. He is excited to be a part of
                            Zenith Software, while creating software that solves problems and makes people's
                            lives easier!"
                        />
                        <TeamCard
                            img={Image8}
                            name="Calvin W. Feldt"
                            position="Junior Software Engineer"
                            linkedIn="https://www.linkedin.com/in/calvin-feldt-9a8988235/"
                            gitHub="https://github.com/C-Feldt"
                            instagram='https://www.instagram.com/feldthat?igsh=MXFwYzEzNHVqbWd3ZA=='
                            bio="Calvin is a Computer Science undergraduate at LSU. He has professional experience
                            in web development, software testing, and full-stack development. He is passionate about
                            creating solutions that are both functional and aesthetically pleasing using new and emerging
                            technologies. He plans to continue his education by pursuing a PhD in Computer Science and
                            conducting research into Human-Centered Computing. He is also from Atlanta, GA and, much to
                            the chagrin of his coworkers, supports the Atlanta Falcons. God bless his soul. Rise up."
                        />
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section id="team-section" className="pt-20 pb-48">
            <div className="TeamSection"></div>
            <div data-aos='fade' className="max-w-7xl mx-auto px-4">
                <Title heading="Meet the Team">
                Our team is comprised of several dedicated Computer Science majors from LSU. Be sure to check out our
                LinkedIn, GitHub, and Instagram profiles to connect with us!
                </Title>
                <div>
                    <TeamPicLeft
                        img={Image1}
                        name="Blake Bollinger"
                        position="CEO, Co-Founder"
                        linkedIn='https://www.linkedin.com/in/blake-bollinger/'
                        gitHub='https://github.com/blakebollinger'
                        instagram='https://www.instagram.com/blakelbollinger/'
                        bio='Blake is a junior at LSU studying Computer Science. He has a passion for mobile app development
                        and creating products people love. He has worked on a wide variety of projects including Apple award-winning machine
                        learning applications, native iOS and Android apps for Fortune 500 companies, and cutting-edge augmented reality
                        research. He brings his passion to Zenith Software to help take the company’s software development to the next level.'

                    />
                    <TeamPicRight
                        img={Image2}
                        name="Darrin Lea"
                        position="CTO, Co-Founder"                                          
                        linkedIn='https://www.linkedin.com/in/darrin-lea/'
                        gitHub='https://github.com/dlea8'
                        instagram='https://www.instagram.com/darrinlea8/'
                        bio='Darrin is an undergraduate Computer Science and Math major at LSU. He specializes in native Android Development,
                        backend development, and full-stack Web Development with frameworks such as .NET and ReactJS. Along with five years of
                        independent experience, Darrin has industry experience with Android Development through 2021 and 2022 summer internships.
                        He also has one year of industry experience with full-stack Web Development.'

                    />
                    <TeamPicLeft
                        img={Image4}
                        name="John Bajor"
                        position="Head of Design, Co-Founder"

                        linkedIn='https://www.linkedin.com/in/johnbajor/'
                        gitHub='https://github.com/jjbajor'
                        instagram='https://www.instagram.com/jjbajor/'
                        bio='John, a senior studying Computer Science at LSU, has been in love with the creative side
                        of technology for his entire life. From experimenting with modding his old game systems as a
                        kid to building his first PC by hand in high school, he has always been learning new and interesting 
                        ways to personalize his experience with technology. Having a passion for frontend design and 
                        a multiple years of experience as a software developer, he is constantly expanding his knowledge of 
                        all things electronic.'

                    />
                    <TeamPicRight
                        img={Image5}
                        name="Trevor Baughman"
                        position="Software Engineer"
                        //linkedIn=''
                        gitHub='https://github.com/trevbaugh'
                        instagram='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                        bio="Trevor is a senior studying Computer Science at LSU. He started out developing
                        in Java and JavaScript and has worked on several websites. He has recently started using Python
                        and TypeScript. He enjoys playing video games and is very passionate about desktop computer hardware.
                        He is very excited to work on projects with Zenith and gain experience in the industry.
                        Trevor is very excited help to Zenith grow while working with the company."
                    />
                    <TeamPicLeft
                        img={Image6}
                        name="Tre Landaiche"
                        position="Software Engineer"
                        linkedIn='https://www.linkedin.com/in/tre-landaiche/'
                        gitHub='https://github.com/ThatGuyTre'
                        //instagram=''
                        bio="Tre Landaiche is a Cybersecurity undergraduate with a knack
                        for creating good-looking software. Since joining the crew in
                        March of '22, he has gained experience with Web Development
                        using React frameworks alongside TypeScript. Java, C, and
                        Python are also in his toolbelt and used for smaller side
                        projects. Recently, Tre has gotten into trading card and
                        strategy games, which inspire many ideas for future projects.
                        He hopes to continue with Zenith as long as time allows because
                        of the exposure to working in a professional environment."
                    />
                    <TeamPicRight
                            img={Image7}
                            name="Bryce Doyle"
                            position="Junior Software Engineer"
                            linkedIn='https://www.linkedin.com/in/bryce-doyle/'
                            gitHub='https://github.com/bdoyle125'
                            instagram='https://www.instagram.com/bdoyle125/'
                            bio="Bryce Doyle is a Software Engineering undergraduate at LSU who
                            has a passion for creating software that solves problems. He has
                            experience using the React framework with TypeScript and JavaScript, as well as
                            coding in Java, C, and C#. Bryce also has experience in automated testing,
                            mocking tests using the Playwright framework. He is excited to be a part of
                            Zenith Software, while creating software that solves problems and makes people's
                            lives easier!"
                    />
                    <TeamPicLeft
                        img={Image8}
                        name="Calvin W. Feldt"
                        position="Junior Software Engineer"
                        linkedIn="https://www.linkedin.com/in/calvin-feldt-9a8988235/"
                        gitHub="https://github.com/C-Feldt"
                        instagram='https://www.instagram.com/feldthat?igsh=MXFwYzEzNHVqbWd3ZA=='
                        bio="Calvin is a Computer Science undergraduate at LSU. He has professional experience
                        in web development, software testing, and full-stack development. He is passionate about
                        creating solutions that are both functional and aesthetically pleasing using new and emerging
                        technologies. He plans to continue his education by pursuing a PhD in Computer Science and
                        conducting research into Human-Centered Computing. He is also from Atlanta, GA and, much to
                        the chagrin of his coworkers, supports the Atlanta Falcons. God bless his soul. Rise up."
                    />
                </div>                
            </div>
        </section>
    );
}