import Title from 'components/landing/Title';
import ContactCard from 'components/landing/ContactCard';
import React, {useEffect, useState} from "react";
import emailjs from 'emailjs-com';
import Form from 'components/landing/Form';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function ContactSection() {

    useEffect(() => {
        AOS.init({duration: 1000});
    }, []);

    return (
        <section id="contact-section" className="pb-20 relative block bg-gray-100">
            <div data-aos='fade' data-aos-duration="1000" className="ContactSection">
                <div className="container max-w-7xl mx-auto px-4 pt-20">
                    <Form />
                </div>
            </div>
        </section>
    );
}