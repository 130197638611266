import Image from '@material-tailwind/react/Image';
import H6 from '@material-tailwind/react/Heading6';
import Paragraph from '@material-tailwind/react/Paragraph';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, {useEffect, useState} from "react";

export default function LandingTeamCard({ img, name, position, linkedIn, gitHub, instagram, bio  }) {

    useEffect(() => {
        AOS.init({duration: 1000});
    }, []);

    return (
        <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div data-aos='fade' className="px-6">
                <Image src={img} alt={name} raised />
                <div className="pt-6 text-center">
                    <H6 color="gray">{name}</H6>
                    <Paragraph color="blueGray" style={{margin: 0, justifyItems: 'center'}}>{position}</Paragraph>
                    <div className="flex items-center justify-center">
                        <a
                            href={linkedIn}
                            target="_blank"
                        >
                            <Button                               
                                color="lightBlue"
                                buttonType="link"
                                ripple="dark"
                                rounded
                                iconOnly                            
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fab fa-linkedin"
                                    size="lg"
                                />
                            </Button>   
                        </a>                        
                        <a
                            href={gitHub}
                            target="_blank"
                        >
                            <Button                                
                                color="black"
                                buttonType="link"
                                ripple="dark"
                                rounded
                                iconOnly
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fab fa-github"
                                    size="lg"
                                />
                            </Button>
                        </a>
                        <a
                            href={instagram}
                            target="_blank"
                        >
                            <Button                            
                                color="pink"
                                buttonType="link"
                                ripple="dark"
                                rounded
                                iconOnly
                            >
                                <Icon
                                    family="font-awesome"
                                    name="fab fa-instagram"
                                    size="lg"
                                />
                            </Button>
                        </a>
                    </div>

                </div>
            </div>
            <div data-aos='fade' className='text-center px-6'>
                <Paragraph color="black" style={{paddingTop: 16}}>{bio}</Paragraph>
            </div>
        </div>
    );
}