import H2 from '@material-tailwind/react/Heading2';
import LeadText from '@material-tailwind/react/LeadText';
import "assets/styles/background.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, {useEffect, useState} from "react";

export default function Header() {

    useEffect(() => {
        AOS.init({
            // delay: 1000,
            startEvent: 'load'
        });
    }, []);

    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="our-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container max-w-8xl relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div data-aos-duration="2000" data-aos='fade' className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        <H2 color="white">Welcome to</H2>
                        <H2 color="white">Zenith Software</H2>
                        <div className="text-gray-200">
                            <LeadText color="gray-200">
                                Welcome to Zenith Software! Here at Zenith, 
                                we specialize in delivering dynamic, 
                                innovative web and mobile solutions to our customers. 
                                {/* This is the future home of Zenith Software.
                                We focus on in house designs of innovative mobile products.
                                Stay tuned for updates on our progress. */}
                            </LeadText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
